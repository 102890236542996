<template>
  <TableServer
    ref="ordersTable"
    :load-items="loadItems"
    :columns="columns"
    title-key="orders.title"
    data-cy="ordersTable"
  >
    <template #actions="{ reload }">
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" xl="2" lg="2" md="4" sm="4">
              <Autocomplete
                v-model="filter.programId"
                data-cy="filterProgram"
                append-inner-icon="i-mdi:arrow-down-drop"
                :label="t('orders.program')"
                title-key="name"
                hide-details
                clearable
                :get-data="loadPrograms"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="4" sm="4">
              <Autocomplete
                v-model="filter.playerId"
                data-cy="filterPlayer"
                append-inner-icon="i-mdi:arrow-down-drop"
                :label="t('orders.player')"
                title-key="displayName"
                hide-details
                clearable
                :get-data="loadPlayers"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="4">
              <DateField
                v-model="filter.orderDateFrom"
                data-cy="filterDateFrom"
                label-key="orders.dateFrom"
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="4">
              <DateField
                v-model="filter.orderDateTo"
                data-cy="filterDateTo"
                label-key="orders.dateTo"
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="4">
              <Select
                v-model="filter.state"
                data-cy="filterState"
                :label="t('orders.state')"
                :items="orderStates"
                item-title="title"
                item-value="value"
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" class="me-auto">
              <Select
                v-model="filter.isFinished"
                data-cy="filterIsFinished"
                :label="t('orders.isFinished')"
                :items="orderFinished"
                item-title="title"
                item-value="value"
                hide-details
                clearable
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="hasCreatePermission" cols="12" md="auto" class="text-end">
          <Btn
            :block="isMobile"
            data-cy="ordersAdd"
            prepend-icon="i-mdi:plus"
            @click="() => openDialog(newOrderId)"
          >
            {{ t('common.add') }}
          </Btn>
          <LazyOrderFormDialog
            :get-dialog-ref="getDialogRef(newOrderId)"
            @submit="reload"
          />
        </v-col>
      </v-row>
    </template>
    <template #[`item.price`]="{ item }">
      {{ formatPrice(item.price) }}
      <template v-if="item.price !== item.paidAmount">
        <v-tooltip location="top">
          <template v-if="item.price > item.paidAmount">
            {{
              t('orders.underpayment', {
                amount: formatPrice(item.price - item.paidAmount),
              })
            }}
          </template>
          <template v-else>
            {{
              t('orders.overpayment', {
                amount: formatPrice(item.paidAmount - item.price),
              })
            }}
          </template>

          <template #activator="{ props: tooltipProps }">
            <span
              v-if="item.price > item.paidAmount"
              v-bind="tooltipProps"
              class="py-1 px-2 text-red"
            >
              {{ formatPrice(item.price - item.paidAmount) }}
            </span>
            <span v-else v-bind="tooltipProps" class="py-1 px-2 text-green">
              +{{ formatPrice(item.paidAmount - item.price) }}
            </span>
          </template>
        </v-tooltip>
      </template>
    </template>
    <template
      v-if="hasViewPermission || hasUpdatePermission || hasDeletePermission"
      #item-actions="{ item, index, reload }"
    >
      <Btn
        v-if="hasViewPermission && !hasUpdatePermission"
        :data-cy="`orderView-${index}`"
        icon="i-mdi:eye"
        variant="text"
        color="primary"
        tile
        density="compact"
        @click="() => openDialog(`view-${item.id}`)"
      />
      <LazyOrderFormDialog
        :get-dialog-ref="getDialogRef(`view-${item.id}`)"
        :initial-value="item"
        disabled
      />
      <Btn
        v-if="hasUpdatePermission"
        :data-cy="`orderEdit-${index}`"
        icon="i-mdi:pencil-outline"
        variant="text"
        color="primary"
        tile
        density="compact"
        @click="() => openDialog(`edit-${item.id}`)"
      />
      <LazyOrderFormDialog
        :get-dialog-ref="getDialogRef(`edit-${item.id}`)"
        :initial-value="item"
        @submit="reload"
      />
      <template v-if="hasDeletePermission">
        <v-tooltip
          v-if="!item.isDeletable"
          :text="t('orders.deleteOrderNotAvailable')"
        >
          <template #activator="{ props }">
            <span v-bind="props">
              <Btn
                :data-cy="`orderDelete-${index}`"
                icon="i-mdi:delete-outline"
                variant="text"
                color="error"
                tile
                density="compact"
                disabled
              />
            </span>
          </template>
        </v-tooltip>
        <Btn
          v-else
          :data-cy="`orderDelete-${index}`"
          icon="i-mdi:delete-outline"
          variant="text"
          color="error"
          tile
          density="compact"
          @click="() => openDialog(`delete-${item.id}`)"
        />
        <LazyConfirmDialog
          :get-dialog-ref="getDialogRef(`delete-${item.id}`)"
          title-key="orders.deleteOrder"
          text-key="orders.deleteOrderText"
          data-cy="orderDeleteDialog"
          :submit="() => deleteItem(item)"
        />
      </template>
    </template>
  </TableServer>
</template>

<script setup lang="ts">
import { UserPermission } from '@/constants/userPermission';
import TableCellActive from '@/components/table-server/cell/TableCellActive.vue';
import { type OrderDto, OrdersService, OrderState } from '@/api';
import type { TableLoadItemsOptions } from '@/types/table';
import { apiKeys } from '@/constants/apiKeys';
import { ResponseStatus } from '@/constants/responseStatus';
import { isEqual } from 'lodash';
import { TableColumnType } from '@/constants/table';
import { SnackbarType } from '@/constants/snackbarType';
import type { DateInput } from '@/types/global';
import TableCellTrainingUnits from '@/components/table-server/cell/TableCellTrainingUnits.vue';
import type Dialog from '@/components/Dialog.vue';
import type { VNodeRef } from 'vue';

const { apiRequest, loadPlayers, loadPrograms } = useApi();
const { t } = useI18n();
const { isMobile } = useCssBreakpoints();
const snackbar = useSnackbar();
const { formatPrice } = useNumberUtils();
useSeoMeta({ title: () => t('layout.default.orders') });

const { hasPermission } = usePermissionUtils();
const hasViewPermission = computed(() =>
  hasPermission(UserPermission.OrdersView),
);
const hasCreatePermission = computed(() =>
  hasPermission(UserPermission.OrdersCreate),
);
const hasUpdatePermission = computed(() =>
  hasPermission(UserPermission.OrdersUpdate),
);
const hasDeletePermission = computed(() =>
  hasPermission(UserPermission.OrdersDelete),
);

const columns = [
  {
    key: 'programName',
    titleKey: 'orders.programName',
    headerProps: {
      'data-cy': 'orderTableHeaderProgramName',
    },
    cellProps: {
      class: 'break-word',
    },
    width: '15%',
  },
  {
    key: 'playerName',
    titleKey: 'orders.playerName',
    headerProps: {
      'data-cy': 'orderTableHeaderPlayerName',
    },
    cellProps: {
      class: 'break-word',
    },
    width: '15%',
    mapper: (item: OrderDto) =>
      `${item.playerFirstName} ${item.playerLastName}`,
  },
  {
    key: 'trainingUnits',
    titleKey: 'orders.trainingUnits',
    width: '20%',
    sortable: false,
    component: TableCellTrainingUnits,
  },
  {
    key: 'orderDate',
    titleKey: 'orders.orderDate',
    headerProps: {
      'data-cy': 'orderTableHeaderOrderDate',
    },
    type: TableColumnType.date,
  },
  {
    key: 'state',
    titleKey: 'orders.state',
    sortable: false,
    mapper: (item: OrderDto) => t(`orderStates.${lcFirst(item.state)}`),
  },
  {
    key: 'isFinished',
    titleKey: 'orders.isFinished',
    headerProps: {
      'data-cy': 'orderTableHeaderIsFinished',
    },
    component: TableCellActive,
  },
  {
    key: 'price',
    titleKey: 'orders.price',
    headerProps: {
      'data-cy': 'orderTableHeaderPrice',
    },
  },
  {
    key: 'actions',
    titleKey: '',
    sortable: false,
  },
];

const orderStates = [
  { title: t('orderStates.paid'), value: OrderState.PAID },
  { title: t('orderStates.unpaid'), value: OrderState.UNPAID },
];

const orderFinished = [
  { title: t('orders.finished'), value: true },
  { title: t('orders.unfinished'), value: false },
];

const filter = reactive<{
  programId: string | null;
  playerId: string | null;
  orderDateFrom: DateInput | null;
  orderDateTo: DateInput | null;
  state: OrderState | null;
  isFinished: boolean | null;
}>({
  programId: null,
  playerId: null,
  orderDateFrom: null,
  orderDateTo: null,
  state: null,
  isFinished: null,
});

const loadItems = async (options: TableLoadItemsOptions) => {
  const { status, data } = await apiRequest(
    () =>
      OrdersService.getOrderListAsync({
        programId: filter.programId ?? undefined,
        playerId: filter.playerId ?? undefined,
        orderDateFrom: filter.orderDateFrom
          ? filter.orderDateFrom.toString()
          : undefined,
        orderDateTo: filter.orderDateTo
          ? filter.orderDateTo.toString()
          : undefined,
        state: filter.state ?? undefined,
        isFinished: filter.isFinished ?? undefined,
        offset: options.offset,
        limit: options.limit,
        sortBy: options.sortBy ?? undefined,
        isSortAscending: options.isSortAscending ?? undefined,
      }),
    apiKeys.orderList(),
  );

  return {
    ok: status?.value === ResponseStatus.success,
    items: data.value?.items ?? ([] as OrderDto[]),
    total: data.value?.totalItems ?? 0,
  };
};

const newOrderId = 'newOrder';
const dialogRefs = ref<Record<string, InstanceType<typeof Dialog>>>({});
const getDialogRef: (id: string) => VNodeRef = (id) => (ref) => {
  dialogRefs.value[id] = ref as InstanceType<typeof Dialog>;
};
const openDialog = (id: string) => {
  dialogRefs.value[id]?.openDialog();
};

const ordersTable = ref();
const tableReload = () => ordersTable.value.reload();

watch(
  () => filter.programId,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      tableReload();
    }
  },
);

watch(
  () => filter.playerId,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      tableReload();
    }
  },
);

watch(
  () => filter.orderDateFrom,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      tableReload();
    }
  },
);

watch(
  () => filter.orderDateTo,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      tableReload();
    }
  },
);

watch(
  () => filter.state,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      tableReload();
    }
  },
);

watch(
  () => filter.isFinished,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      tableReload();
    }
  },
);

const deleteItem = async (item: OrderDto) => {
  const { error } = await apiRequest(() =>
    OrdersService.deleteOrderAsync({
      id: item.id,
    }),
  );

  if (error.value) {
    snackbar.add({
      type: SnackbarType.error,
      text: t('orders.deleteOrderError'),
    });
    return;
  }

  snackbar.add({
    type: SnackbarType.success,
    text: t('orders.deleteOrderSuccess'),
  });

  tableReload();
};
</script>
