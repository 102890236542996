import type {
  TrainingUnitCalendarEventDto,
  TrainingUnitCompositeActivityDto,
} from '@/api';
import { UserPermission } from '@/constants/userPermission';

export const useCalendar = () => {
  const localePath = useLocalePath();
  const { hasPermission } = usePermissionUtils();

  const hasViewPermission = computed(() =>
    hasPermission(UserPermission.CalendarEventsView),
  );
  const hasCreatePermission = computed(() =>
    hasPermission(UserPermission.CalendarEventsCreate),
  );

  const canClickTrainingUnit = (
    calendarEvent?: TrainingUnitCalendarEventDto,
  ) => {
    return (
      (hasViewPermission.value && calendarEvent) || hasCreatePermission.value
    );
  };

  const goToCalendar = (
    compositeActivity: TrainingUnitCompositeActivityDto,
    calendarEvent?: TrainingUnitCalendarEventDto,
  ) => {
    if (!canClickTrainingUnit(calendarEvent)) {
      return;
    }

    const query: Record<string, string> = {};
    if (calendarEvent) {
      query.id = calendarEvent.id;
    } else {
      query.compositeActivityId = compositeActivity.id;
    }

    navigateTo({ path: localePath('/app/calendar'), query });
  };

  return {
    canClickTrainingUnit,
    goToCalendar,
  };
};
